<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card style="max-height: 80vh; display: flex; flex-direction: column;">
      <v-card-title class="headline lighten-2 d-flex flex-column pa-0">
        <div class="d-flex flex-row event-title">
          {{ $t("reactions") }}
          <v-btn icon @click="onCloseDialog"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider style="width: 100%;" />
      </v-card-title>
      <v-card-text
        class="d-flex flex-column pt-0"
        style="align-items: center"
        v-if="reactions.length"
      >
        <v-tabs v-model="tab" class="reaction-types">
          <v-tab v-for="(title, index) in titles" :key="index">
            <div v-if="title == 'All'" class="mr-2">{{ $t("all") }}</div>
            <div v-else class="ml-1 mr-2">
              <v-img
                :src="
                  require(`@/assets/svg/reactions/circle/${title.toLowerCase()}.svg`)
                "
                width="25"
                height="25"
                contain
              />
            </div>
            {{ reactions[index].length }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="mt-2 px-2"
          style="width: 100%; max-height: calc(80vh - 200px); overflow: auto;"
        >
          <v-tab-item v-for="(reaction, index) in reactions" :key="index">
            <div v-for="item in reaction" :key="item._id">
              <div class="d-flex flex-row my-2">
                <div style="position: relative">
                  <editable-avatar
                    :image="item.poster_image"
                    :editable="false"
                    :size="40"
                    :shadow="false"
                  />
                  <div class="ml-1 mr-2 icon-bound-small">
                    <v-img
                      :src="
                        require(`@/assets/svg/reactions/circle/${item.type.toLowerCase()}.svg`)
                      "
                      width="16"
                      height="16"
                      contain
                    />
                  </div>
                </div>
                <div class="mx-2">
                  <div
                    class="url-button"
                    style="font-weight: bold"
                    @click="goToProfile(item._id, item.poster_type)"
                  >
                    <span v-if="item.poster_name">
                      {{ item.poster_name }}
                    </span>
                    <span v-else>
                      {{ item.first_name }} {{ item.last_name }}
                    </span>
                  </div>
                  <div>{{ item.poster_type }}</div>
                </div>
              </div>
              <v-divider />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <div
        style="height: 100px;"
        class="w-100 d-flex align-center justify-center"
        v-if="loading"
      >
        <v-progress-circular indeterminate color="amber" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "../../components/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onCloseDialog: {
      type: Function
    },
    _id: String
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      tab: null,
      reactions: [],
      titles: [],
      reactionTypes: [
        "Like",
        "Celebrate",
        "Support",
        "Love",
        "Insightful",
        "Curious"
      ]
    };
  },
  methods: {
    ...mapActions("event", {
      getReactions: "getReactions"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    filterData() {
      this.titles = ["All"];
      for (var tI = 0; tI < this.reactionTypes.length; tI++) {
        var ok = false;
        var array = [];
        for (var i = 0; i < this.reactions[0].length; i++) {
          if (this.reactions[0][i].type == this.reactionTypes[tI]) {
            ok = true;
            array.push(this.reactions[0][i]);
          }
        }
        if (ok) {
          this.titles.push(this.reactionTypes[tI]);
          this.reactions.push(array);
        }
      }
    },
    goToProfile(poster, type) {
      if (type == "Agency") {
        let routeData = this.$router.resolve({
          name: "agency-profile-details",
          params: { id: poster }
        });
        window.open(routeData.href, "_blank");
      } else if (type == "Volunteer") {
        let routeData = this.$router.resolve({
          name: "volunteer-profile-details",
          params: { id: poster }
        });
        window.open(routeData.href, "_blank");
      }
    },
    getIconStyle(item) {
      if (item == "Like" || item == "Celebrate" || item == "Support")
        return "padding-bottom: 0px; padding-left:0.5px";
      else {
        return "padding-bottom: 0px; padding-left:0px";
      }
    },
    getSmallIconStyle(item) {
      if (item == "Like" || item == "Celebrate" || item == "Support")
        return "padding-bottom: 6px; padding-left:2.5px";
      else {
        return "padding-bottom: 6px; padding-left:2px";
      }
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  },
  mounted() {
    this.reactions = [];
    this.loading = true;
    this.getReactions({ _id: this._id })
      .then(res => {
        this.loading = false;
        this.reactions.push(res);
        this.filterData();
      })
      .catch(error => {
        this.loading = false;
        console.log(error.response.data.message);
      });
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.reaction-types :deep(.v-tab) {
  min-width: 18px;
}
.icon-bound-small {
  position: absolute;
  bottom: 3px;
  right: -7px;
}
.event-title {
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
}
div :deep(.v-dialog) {
  max-height: 80%;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}
</style>
